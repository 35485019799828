<template>
  <v-container>
    <h1 v-html="$t('search_label_data_pivot_title')"></h1>
    <v-form ref="labelDataSearch" @submit.prevent="search()">
      <v-row class="row search spacer-md">
        <!-- Label  -->
        <v-col cols="12" sm="6" md="4" lg="2">
          <search-label-data ref="search-label-data" @search="search" />
        </v-col>
        <!-- Kontakt-->
        <v-col cols="12" sm="6" md="4" lg="2">
          <search-contact ref="search-contact" @search="search" />
        </v-col>
        <!-- Milchverwerter -->
        <v-col cols="12" sm="6" md="4" lg="2">
          <search-dairy ref="search-dairy" @search="search" />
        </v-col>
        <!-- Landwirtschafter Betrieb -->
        <v-col cols="12" sm="6" md="4" lg="2">
          <search-farm ref="search-farm" @search="search" />
        </v-col>
        <!-- Rechtliche Person -->
        <v-col cols="12" sm="6" md="4" lg="2">
          <search-legal-entity ref="search-legal-entity" @search="search" />
        </v-col>
        <!-- Anzeige -->
        <v-col cols="12" sm="6" md="4" lg="2">
          <search-label-visualization ref="search-label-visualization" @search="search" />
        </v-col>
      </v-row>
      <v-btn color="primary" :loading="loading" @click="search"><span v-html="$t('search_label_orders_search')" /></v-btn>
      <v-btn color="secondary" class="margin-left" @click="reset"><span v-html="$t('search_label_orders_new_search')" /></v-btn>
      <v-btn color="secondary" @click="download" :loading="downloadLoading"><span v-html="$t('search_label_orders_download')" /></v-btn>
    </v-form>
    <v-data-table-server
      density="compact"
      :headers="fields"
      :items="items"
      item-value="id"
      class="zero-width"
      :fields="fields"
      :loading="loading"
      :items-length="totalElements"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      :items-per-page-options="$itemsPerPageOptions"
    >
      <template v-slot:[`item.legalEntityAgisId`]="{ item }">
        <template v-if="$privileges.has({ path: '/legalEntities', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_legal_entities_edit',
              params: { id: item.legalEntityId, tab: 'overview' }
            }"
          >
            {{ item.legalEntityAgisId }}
          </router-link>
        </template>
        <template v-else>
          <td>
            {{ item.legalEntityAgisId }}
          </td>
        </template>
      </template>
      <template v-slot:[`item.agisId`]="{ item }">
        <template v-if="$privileges.has({ path: '/farms', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_farms_edit',
              params: { id: item.localUnitId, tab: 'overview' }
            }"
          >
            {{ item.agisId ? item.agisId : $t('none') }}
          </router-link>
        </template>
        <template v-else>
          <td>
            {{ item.agisId ? item.agisId : $t('none') }}
          </td>
        </template>
      </template>
      <template v-slot:[`item.details`]="{ item }">
        <template v-if="$privileges.has({ path: '/labelData', permission: 'details' })">
          <router-link
            :to="{
              name: 'label_data_details',
              params: { localUnitId: item.localUnitId }
            }"
            class="tableaction link"
          >
            <v-icon color="#00A1DB">mdi-text-box-outline</v-icon>
          </router-link>
        </template>
      </template>
      <template v-slot:[`item.deliveredFrom`]="{ item }">
        <template v-if="item.deliveredFrom">
          {{ $formatChDate(item.deliveredFrom) }}
        </template>
      </template>
      <template v-slot:[`item.deliveredUntil`]="{ item }">
        <template v-if="item.deliveredUntil">
          {{ $formatChDate(item.deliveredUntil) }}
        </template>
      </template>
      <template v-slot:[`item.deliveredQuantity`]="{ item }">
        <template v-if="item.deliveredQuantity">
          {{ $formatNumber(item.deliveredQuantity, 1) }}
        </template>
      </template>
      <template v-slot:[`item.animalTypeId`]="{ item }">
        <template v-if="item.animalTypeId">
          {{ animalTypes[item.animalTypeId - 1]['name' + $route.params.upLang] }}
        </template>
      </template>
    </v-data-table-server>
  </v-container>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import { Term } from '@/services/term'
import { fileCreator, showError } from '@/services/axios'

import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import searchFarm from '@/components/searchCards/searchFarm.vue'
import { useSearchFarmStore } from '@/store/SearchFarmStore'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import searchLegalEntity from '@/components/searchCards/searchLegalEntity.vue'
import { useSearchLegalEntityStore } from '@/store/SearchLegalEntityStore'
import searchLabelData from '@/components/searchCards/searchLabelData.vue'
import searchLabelVisualization from '@/components/searchCards/searchLabelVisualization.vue'
import { useSearchLabelStore } from '@/store/SearchLabelStore'
import { useSearchLabelVisualizationStore } from '@/store/SearchLabelVisualizationStore'
import { useAnimalTypesStore } from '@/store/enums/AnimalTypesStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'label_data_read',
  data() {
    return {
      items: [],
      totalElements: 0,
      loading: false,
      downloadLoading: false,

      query: { page: 0, size: 10 }
    }
  },
  computed: {
    animalTypes() {
      return useAnimalTypesStore().AnimalTypes
    },
    fields() {
      return [
        // details icon
        {
          key: 'details',
          sortable: false,
          width: '2%',
          isReduced: true,
          showEvenWithHiddenQuantity: true
        },
        // AGIS-ID Betrieb
        {
          title: this.$t('label_data_pivot_table_agis_id_farm'),
          key: 'agisId',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: true
        },
        // AGIS-ID Person
        {
          title: this.$t('label_data_pivot_table_agis_id_person'),
          key: 'legalEntityAgisId',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: true
        },
        // Name / Firma
        {
          title: this.$t('label_data_pivot_table_name_company'),
          key: 'legalEntityName1',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: true
        },
        // Vorname / Zusatz
        {
          title: this.$t('label_data_pivot_table_name_addition'),
          key: 'legalEntityName2',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: true
        },
        // PLZ
        {
          title: this.$t('label_data_pivot_table_zip'),
          key: 'legalEntityZip',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: true
        },
        // Ort
        {
          title: this.$t('label_data_pivot_table_localty'),
          key: 'legalEntityLocality',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: true
        },
        // Info
        {
          title: this.$t('label_data_pivot_table_info'),
          key: 'localUnitType',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: true
        },
        // Art
        {
          title: this.$t('label_data_pivot_table_type'),
          key: 'animalTypeId',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: true
        },
        // QM-SF
        {
          title: this.$t('label_data_pivot_table_qm_sf'),
          key: 'labelTypeQmsf',
          sortable: true,
          isReduced: false,
          showEvenWithHiddenQuantity: true
        },
        // IPS-GA
        {
          title: this.$t('label_data_pivot_table_ips_ga'),
          key: 'labelTypeIpSuisse',
          sortable: true,
          isReduced: false,
          showEvenWithHiddenQuantity: true
        },
        // SG
        {
          title: this.$t('label_data_pivot_table_sg'),
          key: 'labelTypeSuisseGarantie',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: true
        },
        // GT
        {
          title: this.$t('label_data_pivot_table_gt'),
          key: 'labelTypeGruenerTeppich',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: true
        },
        // IPS-WM
        {
          title: this.$t('label_data_pivot_table_ips_wm'),
          key: 'labelTypeIpSuisseWiesenmilch',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: true
        },
        // BioV U1
        {
          title: this.$t('label_data_pivot_table_biov_u1'),
          key: 'labelTypeBioVU1',
          sortable: true,
          isReduced: false,
          showEvenWithHiddenQuantity: true
        },
        // BioK U1
        {
          title: this.$t('label_data_pivot_table_biok_u1'),
          key: 'labelTypeBioKnospeU1',
          sortable: true,
          isReduced: false,
          showEvenWithHiddenQuantity: true
        },
        // BioV U2
        {
          title: this.$t('label_data_pivot_table_biov_u2'),
          key: 'labelTypeBioVU2',
          sortable: true,
          isReduced: false,
          showEvenWithHiddenQuantity: true
        },
        // BioK U2
        {
          title: this.$t('label_data_pivot_table_biok_u2'),
          key: 'labelTypeBioKnospeU2',
          sortable: true,
          isReduced: false,
          showEvenWithHiddenQuantity: true
        },
        // BioV
        {
          title: this.$t('label_data_pivot_table_biov'),
          key: 'labelTypeBioV',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: true
        },
        // BioK
        {
          title: this.$t('label_data_pivot_table_biok'),
          key: 'labelTypeBioKnospe',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: true
        },
        // Demeter
        {
          title: this.$t('label_data_pivot_table_demeter'),
          key: 'labelTypeBioDemeter',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: true
        },
        // Demeter U1
        {
          title: this.$t('label_data_pivot_table_demeter_u1'),
          key: 'labelTypeDemeterU1',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: true
        },
        // Demeter U2
        {
          title: this.$t('label_data_pivot_table_demeter_u2'),
          key: 'labelTypeDemeterU2',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: true
        },
        // Demeter U3
        {
          title: this.$t('label_data_pivot_table_demeter_u3'),
          key: 'labelTypeDemeterU3',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: true
        },
        // Einlieferung von
        {
          title: this.$t('label_data_pivot_table_received_from'),
          key: 'deliveredFrom',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: false
        },
        //Bis
        {
          title: this.$t('label_data_pivot_table_until'),
          key: 'deliveredUntil',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: false
        },
        // Menge [kg]
        {
          title: this.$t('label_data_pivot_table_quantity_kg'),
          key: 'deliveredQuantity',
          sortable: true,
          isReduced: true,
          showEvenWithHiddenQuantity: false,
          align: 'end'
        }
      ]
        .filter((el) => !useSearchLabelVisualizationStore().reduced || el.isReduced)
        .filter((el) => useSearchLabelVisualizationStore().withMarketedMilk || el.showEvenWithHiddenQuantity)
    },
    term(): any {
      return [
        ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items),
        ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items),
        ...Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items),
        ...Term.buildTermItems(useSearchFarmStore(), useSearchFarmStore().items)
      ]
    },
    queryParams() {
      return {
        ...this.getJavaPageOptions({ sort: ['agisId,asc'] }),
        term: Term.stringify(this.term),
        labelTypeId: useSearchLabelStore().labelTypeId,
        animalTypeId: useSearchLabelStore().animalTypeId,
        complied: useSearchLabelStore().complied,
        dueDate: useSearchLabelStore().dueDate,
        withMarketedMilk: useSearchLabelVisualizationStore().withMarketedMilk,
        receivedFrom: useSearchLabelVisualizationStore().receivedFrom,
        receivedUntil: useSearchLabelVisualizationStore().receivedUntil
      }
    }
  },
  components: {
    searchDairy,
    searchFarm,
    searchContact,
    searchLegalEntity,
    searchLabelData,
    searchLabelVisualization
  },
  methods: {
    search() {
      this.$router
        .push({
          name: 'label_data_read',
          query: this.queryParams
        })
        .catch(() => {
          // route duplicated
          this.load()
        })
    },
    async load() {
      if (!(await this.$refs.labelDataSearch.validate()).valid) {
        return
      }
      this.loading = true
      try {
        this.items = []
        const response = await this.axios.get(apiURL + '/labelData/pivot/search', {
          params: this.queryParams
        })
        const items = await response.data.content
        this.items = Array.isArray(items) ? items : []
        this.items = this.items.map((item) => {
          if (item.labelTypeBioV) item.labelTypeBioV = this.$t('label_data_pivot_table_yes')
          else if (false == item.labelTypeBioV) item.labelTypeBioV = this.$t('label_data_pivot_table_no')
          else item.labelTypeBioV = '*'

          if (item.labelTypeBioVU1) item.labelTypeBioVU1 = this.$t('label_data_pivot_table_yes')
          else if (false == item.labelTypeBioVU1) item.labelTypeBioVU1 = this.$t('label_data_pivot_table_no')
          else item.labelTypeBioVU1 = '*'

          if (item.labelTypeBioVU2) item.labelTypeBioVU2 = this.$t('label_data_pivot_table_yes')
          else if (false == item.labelTypeBioVU2) item.labelTypeBioVU2 = this.$t('label_data_pivot_table_no')
          else item.labelTypeBioVU2 = '*'

          if (item.labelTypeBioKnospe) item.labelTypeBioKnospe = this.$t('label_data_pivot_table_yes')
          else if (false == item.labelTypeBioKnospe) item.labelTypeBioKnospe = this.$t('label_data_pivot_table_no')
          else item.labelTypeBioKnospe = '*'

          if (item.labelTypeBioKnospeU1) item.labelTypeBioKnospeU1 = this.$t('label_data_pivot_table_yes')
          else if (false == item.labelTypeBioKnospeU1) item.labelTypeBioKnospeU1 = this.$t('label_data_pivot_table_no')
          else item.labelTypeBioKnospeU1 = '*'

          if (item.labelTypeBioKnospeU2) item.labelTypeBioKnospeU2 = this.$t('label_data_pivot_table_yes')
          else if (false == item.labelTypeBioKnospeU2) item.labelTypeBioKnospeU2 = this.$t('label_data_pivot_table_no')
          else item.labelTypeBioKnospeU2 = '*'

          if (item.labelTypeBioDemeter) item.labelTypeBioDemeter = this.$t('label_data_pivot_table_yes')
          else if (false == item.labelTypeBioDemeter) item.labelTypeBioDemeter = this.$t('label_data_pivot_table_no')
          else item.labelTypeBioDemeter = '*'

          if (item.labelTypeDemeterU1) item.labelTypeDemeterU1 = this.$t('label_data_pivot_table_yes')
          else if (false == item.labelTypeDemeterU1) item.labelTypeDemeterU1 = this.$t('label_data_pivot_table_no')
          else item.labelTypeDemeterU1 = '*'

          if (item.labelTypeDemeterU2) item.labelTypeDemeterU2 = this.$t('label_data_pivot_table_yes')
          else if (false == item.labelTypeDemeterU2) item.labelTypeDemeterU2 = this.$t('label_data_pivot_table_no')
          else item.labelTypeDemeterU2 = '*'

          if (item.labelTypeDemeterU3) item.labelTypeDemeterU3 = this.$t('label_data_pivot_table_yes')
          else if (false == item.labelTypeDemeterU3) item.labelTypeDemeterU3 = this.$t('label_data_pivot_table_no')
          else item.labelTypeDemeterU3 = '*'

          if (item.labelTypeQmsf) item.labelTypeQmsf = this.$t('label_data_pivot_table_yes')
          else if (false == item.labelTypeQmsf) item.labelTypeQmsf = this.$t('label_data_pivot_table_no')
          else item.labelTypeQmsf = '*'

          if (item.labelTypeIpSuisse) item.labelTypeIpSuisse = this.$t('label_data_pivot_table_yes')
          else if (false == item.labelTypeIpSuisse) item.labelTypeIpSuisse = this.$t('label_data_pivot_table_no')
          else item.labelTypeIpSuisse = '*'

          if (item.labelTypeIpSuisseWiesenmilch) item.labelTypeIpSuisseWiesenmilch = this.$t('label_data_pivot_table_yes')
          else if (false == item.labelTypeIpSuisseWiesenmilch) item.labelTypeIpSuisseWiesenmilch = this.$t('label_data_pivot_table_no')
          else item.labelTypeIpSuisseWiesenmilch = '*'

          if (item.labelTypeGruenerTeppich) item.labelTypeGruenerTeppich = this.$t('label_data_pivot_table_yes')
          else if (false == item.labelTypeGruenerTeppich) item.labelTypeGruenerTeppich = this.$t('label_data_pivot_table_no')
          else item.labelTypeGruenerTeppich = '*'

          if (item.labelTypeSuisseGarantie) item.labelTypeSuisseGarantie = this.$t('label_data_pivot_table_yes')
          else if (false == item.labelTypeSuisseGarantie) item.labelTypeSuisseGarantie = this.$t('label_data_pivot_table_no')
          else item.labelTypeSuisseGarantie = '*'

          return item
        })
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (error) {
        showError(error)
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.$refs['search-contact'].reset()
      this.$refs['search-dairy'].reset()
      this.$refs['search-farm'].reset()
      this.$refs['search-legal-entity'].reset()
      this.$refs['search-label-data'].reset()
      this.$refs['search-label-visualization'].reset()
    },
    back() {
      this.$router.go(-1)
    },
    async download() {
      this.downloadLoading = true
      try {
        const response = await this.axios.get(apiURL + '/labelData/pivot/search', {
          params: this.queryParams,
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(await response, 'labelData.xlsx')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.downloadLoading = false
      }
    },
    $formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    }
  },
  watch: {
    '$route.query': {
      handler(newValue) {
        this.load()
      },
      deep: true
    }
  },
  mounted() {
    if (this.$route.query.size) this.load()
    useAnimalTypesStore().fetchAnimalTypes()
  }
})
</script>
